import { useState, useMemo, useEffect, Dispatch, SetStateAction, useCallback, FC, useRef } from "react";

import { Box } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { differenceBy, isEqual } from "lodash";
import { useQueryClient } from "react-query";
import type { MarkOptional } from "ts-essentials";
import useUndo from "use-undo";
import { v4 as uuid } from "uuid";

import { CustomQuery, isCustomQueryDirty } from "src/components/sources/forms/custom-query";
import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  RunDbtModelQuery,
  RunDbtQuery,
  RunSigmaQuery,
  RunSqlQuery,
  RunTableQuery,
  useUpdateQueryMutation,
  useRunDbtModelQuery,
  useRunDbtQuery,
  useRunLookerLookQuery,
  useRunSqlQuery,
  useRunTableQuery,
  useRunVisualQuery,
  useRunCustomQuery,
  useDeleteModelColumnsMutation,
  useRunSigmaQuery,
  QueryResponse,
  RunVisualQuery,
  ModelColumns,
  RunCustomQuery,
  usePreviewQuerySchemaQuery,
  PreviewQuerySchemaQuery,
  RunLookerLookQuery,
  SegmentsSetInput,
  SuccessfulQueryResponse,
  ModelColumnInput,
  ResourceToPermission,
  ObjectColumnFragment,
  useRunSqlBackgroundQuery,
  RunSqlBackgroundQuery,
  useRunSqlResultQuery,
  useVisualQueryBackgroundResultQuery,
  useRunVisualBackgroundQuery,
  useRunTableBackgroundQuery,
  useRunDbtModelBackgroundQuery,
  useRunLookerLookBackgroundQuery,
  useRunSigmaBackgroundQuery,
  useRunCustomBackgroundQuery,
  useRunDbtBackgroundQuery,
  RunVisualBackgroundQuery,
  RunTableBackgroundQuery,
  RunDbtModelBackgroundQuery,
  RunCustomBackgroundQuery,
  RunDbtBackgroundQuery,
  RunLookerLookBackgroundQuery,
  RunSigmaBackgroundQuery,
  BackgroundJobResult,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { DBTModel, LookerLook, Model, QueryType, Sigma } from "src/types/models";
import { ColumnType, VisualQueryFilter } from "src/types/visual";
import { DBTIcon, SQLIcon, TableIcon } from "src/ui/icons";

export const QueryTypeDictionary: Record<QueryType, string> = {
  [QueryType.RawSql]: "SQL",
  [QueryType.Visual]: "Visual",
  [QueryType.Table]: "Table",
  [QueryType.DbtModel]: "dbt Model",
  [QueryType.Dbt]: "dbt",
  [QueryType.LookerLook]: "Looker Look",
  [QueryType.Sigma]: "Sigma",
  [QueryType.Custom]: "Custom",
};

export const RUN_QUERY = {
  [QueryType.RawSql]: useRunSqlQuery,
  [QueryType.Visual]: useRunVisualQuery,
  [QueryType.Table]: useRunTableQuery,
  [QueryType.Dbt]: useRunDbtQuery,
  [QueryType.DbtModel]: useRunDbtModelQuery,
  [QueryType.LookerLook]: useRunLookerLookQuery,
  [QueryType.Sigma]: useRunSigmaQuery,
  [QueryType.Custom]: useRunCustomQuery,
};

export const ASYNC_RUN_QUERY = {
  [QueryType.RawSql]: useRunSqlBackgroundQuery,
  [QueryType.Visual]: useRunVisualBackgroundQuery,
  [QueryType.Table]: useRunTableBackgroundQuery,
  [QueryType.Dbt]: useRunDbtBackgroundQuery,
  [QueryType.DbtModel]: useRunDbtModelBackgroundQuery,
  [QueryType.LookerLook]: useRunLookerLookBackgroundQuery,
  [QueryType.Sigma]: useRunSigmaBackgroundQuery,
  [QueryType.Custom]: useRunCustomBackgroundQuery,
};

// Error type used to signal that a column deletion error occurred, so the frontend
// can handle it specially.
export class DeleteColumnsError extends Error {
  deletedColumns: string[];

  constructor(message: string, options: ErrorOptions & { deletedColumns: string[] }) {
    super(message, options);
    this.deletedColumns = options.deletedColumns;
  }
}

export const EMPTY_AUDIENCE_DEFINITION = { conditions: [] };

export const getVariables = (
  type: QueryType,
  options: {
    // The id of the model if previewing an existing model.
    modelId?: string;
    variables: any;
  },
): any => {
  switch (type) {
    case QueryType.RawSql: {
      return {
        sql: options?.variables?.sql,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.Visual: {
      return {
        parentModelId: String(options?.variables?.parentModelId),
        filter: options?.variables?.visualQueryFilter || EMPTY_AUDIENCE_DEFINITION,
        audienceId: options.modelId ? options.modelId.toString() : undefined,
      };
    }

    case QueryType.Table: {
      return {
        table: options?.variables?.table,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.Dbt: {
      return {
        dbtModelId: options?.variables?.dbtModel?.id,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.DbtModel: {
      return {
        dbtModelId: options?.variables?.dbtModel?.id,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.LookerLook: {
      return {
        lookId: options?.variables?.lookerLook?.id,
        modelId: Number(options?.modelId),
      };
    }

    case QueryType.Sigma: {
      return {
        elementId: options?.variables?.sigma?.query?.elementId,
        workbookId: options?.variables?.sigma?.query?.workbookId,
        modelId: Number(options?.variables?.modelId),
      };
    }

    case QueryType.Custom: {
      return {
        customQuery: options?.variables?.customQuery,
        modelId: Number(options?.modelId),
      };
    }

    default:
      throw new Error(`Unsupported query type ${type}`);
  }
};

type InnerQueryResult =
  | RunVisualQuery
  | RunSqlQuery
  | RunTableQuery
  | RunDbtModelQuery
  | RunDbtQuery
  | RunLookerLookQuery
  | RunSigmaQuery
  | RunCustomQuery;

type InnerBackgroundQueryResult =
  | RunVisualBackgroundQuery
  | RunSqlBackgroundQuery
  | RunTableBackgroundQuery
  | RunDbtModelBackgroundQuery
  | RunDbtBackgroundQuery
  | RunLookerLookBackgroundQuery
  | RunSigmaBackgroundQuery
  | RunCustomBackgroundQuery;

function getInnerQueryResult(result: InnerQueryResult): QueryResponse {
  return Object.values(result)[0] as QueryResponse;
}

function getBackgroundInnerQueryResult(result: InnerBackgroundQueryResult): BackgroundJobResult {
  return Object.values(result)[0] as BackgroundJobResult;
}

export const useModelRun = (
  type: QueryType | undefined,
  modelColumns: any,
  options: {
    // The id of the model if previewing an existing model.
    modelId?: string;
    variables: any;
    onCompleted?: (data, error) => void;
  },
) => {
  // Store the function to cancel the current query with the query's key.
  const cancelQueryRef = useRef<() => Promise<void>>();

  const client = useQueryClient();
  const { featureFlags } = useUser();
  const { appAsyncPreviewQuery } = useFlags();
  const [loading, setLoading] = useState<boolean>(false);
  const [backgroundJobId, setBackgroundJobId] = useState<string>();
  const [shouldPollForResults, setShouldPollForResults] = useState<boolean>(false);
  const [schemaLoading, setSchemaLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [page, setPage] = useState<number | undefined>();
  const [errorAtLine, setErrorAtLine] = useState<number | undefined>();
  const [runId, setRunId] = useState<string | undefined>("");
  const [result, setResult] = useState<{
    data?: MarkOptional<SuccessfulQueryResponse, "rows" | "numRowsWithoutLimit" | "sql" | "rowsCount">;
    id: string;
  }>({ id: "" });
  const [transformedSql, setTransformedSql] = useState<string | undefined>();

  useEffect(() => {
    // If we aren't doing async pagination or the user hasn't ran the query yet.
    if (!backgroundJobId || page === undefined || backgroundJobId !== runId) {
      return;
    }

    setLoading(true);
    setShouldPollForResults(true);
  }, [page, backgroundJobId, runId]);

  const cancelQuery = async () => {
    await cancelQueryRef.current?.();
    setRunId("");
    setLoading(false);
    setSchemaLoading(false);
    setError(undefined);
    setBackgroundJobId(undefined);
    setShouldPollForResults(false);
    setResult({
      ...result,
      data: undefined,
    });

    // reset cancel function.
    cancelQueryRef.current = undefined;
  };

  const getSchema = async () => {
    if (!type) {
      return undefined;
    }

    setSchemaLoading(true);
    const id = uuid();
    setRunId(id);

    const variables = { sourceId: String(options?.variables?.sourceId), queryType: type, query: getVariables(type, options) };

    const queryKey = usePreviewQuerySchemaQuery.getKey(variables);
    const queryFn = usePreviewQuerySchemaQuery.fetcher(variables);

    let error: Error | undefined = undefined;
    let data: typeof result["data"];

    try {
      const response = await client.fetchQuery<PreviewQuerySchemaQuery>(queryKey, {
        queryFn,
        // Disable caching the query results.
        cacheTime: 0,
      });
      data = { rows: undefined, columns: response.previewQuerySchema.columns, exceedsPreviewMax: false };
    } catch (e) {
      error = e;
      setError(e.message);
      setErrorAtLine(undefined);
    }

    setResult({ id, data });
    setSchemaLoading(false);

    return { data, error };
  };

  const resetRunState = () => {
    setRunId("");
    setLoading(false);
    setSchemaLoading(false);
    setError("");
    setResult({ id: "" });
  };

  useRunSqlResultQuery(
    {
      jobId: backgroundJobId ?? "",
      page: page ?? 0,
    },
    {
      enabled: Boolean(shouldPollForResults && backgroundJobId && type !== QueryType.Visual),
      keepPreviousData: true,
      refetchInterval: 500,
      onError: (error) => {
        Sentry.captureException(error);
        handleErrorResponse(error.message, backgroundJobId || "");
        setShouldPollForResults(false);
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }

        handleSuccessResponse(data.backgroundPreviewQueryResult, backgroundJobId || "");
        setShouldPollForResults(false);
      },
    },
  );

  useVisualQueryBackgroundResultQuery(
    {
      jobId: backgroundJobId ?? "",
      page: page ?? 0,
      filter: options?.variables?.visualQueryFilter || EMPTY_AUDIENCE_DEFINITION,
      parentModelId: options?.variables?.parentModelId?.toString(),
    },
    {
      enabled: Boolean(shouldPollForResults && backgroundJobId && type === QueryType.Visual),
      keepPreviousData: true,
      refetchInterval: 500,
      onError: (error) => {
        Sentry.captureException(error);
        handleErrorResponse(error.message, backgroundJobId || "");
        setShouldPollForResults(false);
      },
      onSuccess: (data) => {
        if (!data.visualQueryBackgroundResult) {
          return;
        }

        handleSuccessResponse(data.visualQueryBackgroundResult, backgroundJobId || "");
        setShouldPollForResults(false);
      },
    },
  );

  const handleErrorResponse = (message: string, id: string) => {
    setError(message);
    setErrorAtLine(undefined);
    setLoading(false);
    setResult({ id, data: undefined });
    setTransformedSql(undefined);
  };

  const handleSuccessResponse = (responseResult: QueryResponse, id: string) => {
    if (responseResult && responseResult.__typename === "FailedQueryResponse") {
      setError(responseResult.error);
      setTransformedSql(responseResult.sql as string);
      const lineNumber = responseResult.metadata?.line;
      if (lineNumber) {
        setErrorAtLine(lineNumber);
      }
      setResult({ id, data: undefined });
      setLoading(false);
      return;
    }

    setErrorAtLine(undefined);
    setError(undefined);
    setResult({ id, data: responseResult as SuccessfulQueryResponse });
    setTransformedSql(responseResult.sql || undefined);
    setLoading(false);
  };

  const runQuery = useCallback(
    async (runOptions: { limit: boolean; disableRowCounter?: boolean }): Promise<void> => {
      if (!type) {
        return undefined;
      }

      setLoading(true);
      const id = uuid();
      setRunId(id);

      const commonVariables = {
        sourceId: String(options?.variables?.sourceId),
        // NOTE: the feature flag names on the right side need to match the names
        // in the database
        disableRowCounter: featureFlags?.sql_row_counter_disabled || runOptions.disableRowCounter ? true : undefined,
        // In the backend we actually query for 101 rows, but only return 100,
        // so that we can tell if the result is truncated.
        limit: runOptions.limit ? 100 : undefined,
      };

      const variables = { ...commonVariables, ...getVariables(type, options) };

      const queryKey = RUN_QUERY[type].getKey(variables);
      const queryFn = RUN_QUERY[type].fetcher(variables);

      if (type in ASYNC_RUN_QUERY && appAsyncPreviewQuery.includes(type)) {
        try {
          const queryKey = ASYNC_RUN_QUERY[type].getKey(variables);
          const queryFn = ASYNC_RUN_QUERY[type].fetcher(variables);

          // set ref to cancel the current query
          cancelQueryRef.current = () => client.cancelQueries(queryKey);

          const response = await client.fetchQuery<InnerBackgroundQueryResult>(queryKey, {
            queryFn,
            // Disable caching the query results.
            cacheTime: 0,
          });

          // If the cancel function has been reset then the query was cancelled.
          if (!cancelQueryRef.current) {
            return;
          }

          const responseResult = getBackgroundInnerQueryResult(response);
          setBackgroundJobId(responseResult.jobId);

          // Update the run ID here so when the response comes back, we know
          // whether it is still to the request we care about.
          setRunId(responseResult.jobId);
          setPage(0);
          setShouldPollForResults(true);
        } catch (err) {
          handleErrorResponse(err.message, id);
          Sentry.captureEvent(err);
        }
        return;
      }

      let responseResult: QueryResponse | null = null;

      // set ref to cancel the current query
      cancelQueryRef.current = () => client.cancelQueries(queryKey);

      try {
        const response = await client.fetchQuery<InnerQueryResult>(queryKey, {
          queryFn,
          // Disable caching the query results.
          cacheTime: 0,
        });

        // If the cancel function has been reset then the query was cancelled.
        if (!cancelQueryRef.current) {
          return;
        }

        responseResult = getInnerQueryResult(response);
        handleSuccessResponse(responseResult, id);
      } catch (err) {
        handleErrorResponse(err.message, id);
      }

      if (responseResult && responseResult.__typename === "FailedQueryResponse") {
        setError(responseResult.error);
        setTransformedSql(responseResult.sql as string);
        const lineNumber = responseResult.metadata?.line;
        if (lineNumber) {
          setErrorAtLine(lineNumber);
        }
        setResult({ id, data: undefined });
        setLoading(false);
        return;
      }
    },
    [client, featureFlags, options, type],
  );

  useEffect(() => {
    if (!result.data) {
      return;
    }
    if (result.id === runId) {
      if (options?.onCompleted) {
        options.onCompleted(
          {
            columns: result.data?.columns?.map(({ name, type, raw_type }) => ({ name, type, raw_type })), //remove __typename,
            rows: result.data?.rows,
          },
          undefined,
        );
      }
    }
  }, [runId, result]);

  const rows = useMemo(() => {
    return result.data?.rows?.map((row) => aliasRow(row, modelColumns));
  }, [result.data?.rows, modelColumns]);

  const columns = useMemo(
    () => aliasColumns(result.data?.columns, modelColumns)?.map(({ name, type, raw_type }) => ({ name, type, raw_type })),
    [result.data?.columns, modelColumns],
  ); //remove __typename

  const useAsyncPagination = Boolean(backgroundJobId) && page !== undefined;
  return {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    schemaLoading,
    loading,
    error,
    errorAtLine,
    columns: columns ?? [],
    transformedSql,
    rawColumns: result.data?.columns?.map(({ name, type, raw_type }) => ({ name, type, raw_type })),
    rows,
    numRowsWithoutLimit: Number(result.data?.numRowsWithoutLimit),
    isResultTruncated: result.data?.exceedsPreviewMax,
    asyncPagination: useAsyncPagination,
    rowsCount: Number(result.data?.rowsCount),
    page: useAsyncPagination ? page : undefined,
    setPage: useAsyncPagination ? setPage : undefined,
  };
};

interface UseQueryStateResult {
  queryState: QueryState;
  isDirty: (model: Model | null) => boolean;
  isQueryDefined: (type: QueryType | undefined) => boolean;
  resetQueryState: () => void;
  initQueryState: (model: Model | null | undefined) => void;
  undoVisualQueryFilterChange: () => void;
  redoVisualQueryFilterChange: () => void;
  resetVisualQueryFilter: (newState: VisualQueryFilter) => void;
  canUndoVisualQueryFilterChange: boolean;
  canRedoVisualQueryFilterChange: boolean;
  setSQL: Dispatch<SetStateAction<string>>;
  setVisualQueryFilter: (conditions: VisualQueryFilter) => void;
  setTable: Dispatch<SetStateAction<string>>;
  setDBTModel: Dispatch<SetStateAction<DBTModel | undefined>>;
  setLookerLook: Dispatch<SetStateAction<LookerLook | undefined>>;
  setSigma: Dispatch<SetStateAction<Sigma | undefined>>;
  setCustomQuery: Dispatch<SetStateAction<CustomQuery | undefined>>;
}

export interface QueryState {
  sql: string | undefined | null;
  visualQueryFilter: VisualQueryFilter | undefined;
  table: string | undefined | null;
  dbtModel: DBTModel | undefined;
  lookerLook: LookerLook | undefined;
  customQuery: CustomQuery | undefined;
  sigma: Sigma | undefined;
}

export const useQueryState = (): UseQueryStateResult => {
  const [sql, setSQL] = useState<string>("");
  const [
    visualQueryFilter,
    {
      set: setVisualQueryFilter,
      reset: resetVisualQueryFilter,
      undo: undoVisualQueryFilterChange,
      redo: redoVisualQueryFilterChange,
      canUndo: canUndoVisualQueryFilterChange,
      canRedo: canRedoVisualQueryFilterChange,
    },
  ] = useUndo<VisualQueryFilter | undefined>(undefined);
  const [table, setTable] = useState<string>("");
  const [dbtModel, setDBTModel] = useState<DBTModel | undefined>();
  const [lookerLook, setLookerLook] = useState<LookerLook | undefined>();
  const [customQuery, setCustomQuery] = useState<CustomQuery | undefined>();
  const [sigma, setSigma] = useState<Sigma | undefined>();

  const reset = () => {
    setSQL("");
    resetVisualQueryFilter(undefined);
    setTable("");
    setDBTModel(undefined);
    setLookerLook(undefined);
    setCustomQuery(undefined);
    setSigma(undefined);
  };

  const init = (model: Model | null | undefined) => {
    setSQL(model?.query_raw_sql ?? "");
    resetVisualQueryFilter(model?.visual_query_filter ?? EMPTY_AUDIENCE_DEFINITION);
    setTable(model?.query_table_name ?? "");
    setDBTModel({ id: model?.query_dbt_model_id } as DBTModel);
    setLookerLook({ id: model?.query_looker_look_id, title: "" });
    setCustomQuery(model?.custom_query);
    setSigma(model?.query_integrations?.query?.queryType === QueryType.Sigma ? model?.query_integrations : undefined);
  };

  const queryState = useMemo(
    () => ({
      sql,
      visualQueryFilter: visualQueryFilter.present,
      table,
      dbtModel,
      lookerLook,
      customQuery,
      sigma,
    }),
    [sql, visualQueryFilter, table, dbtModel, lookerLook, customQuery, sigma],
  );

  const isDirty = (model: Model | null): boolean => {
    return (
      queryState?.sql !== model?.query_raw_sql ||
      queryState?.table !== model?.query_table_name ||
      queryState?.dbtModel?.id !== model?.query_dbt_model_id ||
      queryState?.lookerLook?.id !== model?.query_looker_look_id ||
      isCustomQueryDirty(queryState?.customQuery, model?.custom_query) ||
      !isEqual(queryState?.sigma, model?.query_integrations)
    );
  };

  const isQueryDefined = (type: QueryType | undefined) => {
    switch (type) {
      case QueryType.RawSql:
        return Boolean(sql);
      case QueryType.Table:
        return Boolean(table);
      case QueryType.Dbt:
        return Boolean(dbtModel);
      case QueryType.DbtModel:
        return Boolean(dbtModel);
      case QueryType.LookerLook:
        return Boolean(lookerLook);
      case QueryType.Custom:
        return Boolean(customQuery);
      case QueryType.Sigma:
        return Boolean(sigma);
      default:
        return false;
    }
  };

  return {
    queryState,
    isDirty,
    isQueryDefined,
    resetQueryState: reset,
    initQueryState: init,
    setSQL,
    redoVisualQueryFilterChange,
    undoVisualQueryFilterChange,
    resetVisualQueryFilter,
    canUndoVisualQueryFilterChange,
    canRedoVisualQueryFilterChange,
    setVisualQueryFilter,
    setTable,
    setDBTModel,
    setLookerLook,
    setCustomQuery,
    setSigma,
  };
};

export const useModelState = () => {
  const [name, setName] = useState("");
  const [timestampColumn, setTimestampColumn] = useState("");
  const [primaryKey, setPrimaryKey] = useState("");
  const [description, setDescription] = useState<string>("");

  const modelState = useMemo(
    () => ({
      name,
      timestampColumn,
      primaryKey,
      description,
    }),
    [name, primaryKey, timestampColumn, description],
  );

  const init = (model) => {
    setName(model?.name);
    setPrimaryKey(model?.primary_key);
    setTimestampColumn(model?.event?.timestamp_column);
    setDescription("");
  };

  return {
    modelState,
    initModelState: init,
    setName,
    setPrimaryKey,
    setTimestampColumn,
    setDescription,
  };
};

export const useUpdateQuery = ({ logUpdate } = { logUpdate: true }) => {
  const { user } = useUser();
  const { mutateAsync: updateQuery } = useUpdateQueryMutation();
  const { mutateAsync: deleteColumns } = useDeleteModelColumnsMutation();
  const { updateResourceOrDraft, resourceType } = useDraft();

  const update = async ({
    model,
    queryState = null,
    columns,
    topKSyncInterval,
    overwriteMetadata,
  }: {
    model: Model | null | undefined;
    queryState?: QueryState | null;
    columns: ModelColumnInput[] | undefined;
    topKSyncInterval?: number;
    overwriteMetadata?: boolean;
  }) => {
    const source = model?.connection;
    const type = model?.query_type;

    const deletedColumns = differenceBy(model?.columns, columns ?? [], "name");

    const update: SegmentsSetInput = {
      query_dbt_model_id: queryState?.dbtModel?.id,
      query_looker_look_id: queryState?.lookerLook?.id,
      query_raw_sql: queryState?.sql,
      query_table_name: queryState?.table,
      query_integrations: queryState?.sigma,
      visual_query_filter: queryState?.visualQueryFilter,
      custom_query: queryState?.customQuery,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (logUpdate && user?.id) {
      update.updated_by = String(user?.id);
    }

    const modelColumns = columns
      ? columns.map((column) => ({
          ...column,
          model_id: model?.id,
          // add top_k_sync_interval to new columns
          top_k_sync_interval: topKSyncInterval,
        }))
      : [];

    if (overwriteMetadata && model && columns) invalidateColumnMetadata(model, modelColumns);

    const updateFunc = async () => {
      if (deletedColumns.length) {
        try {
          await deleteColumns({ modelId: model?.id, names: deletedColumns.map(({ name }) => name) });
        } catch (err) {
          // Throw an error and abort the update - if the column deletion fails, we don't want to continue with the
          // update because it would potentially break relationships that depend on one of the columns that were
          // removed in the update. The user should be prompted to fix this first.
          throw new DeleteColumnsError("failed to delete columns", {
            cause: err,
            deletedColumns: deletedColumns.map((c) => c.name),
          });
        }
      }

      await updateQuery({
        id: model?.id,
        model: update,
        columns: modelColumns,
        overwriteMetadata,
      });

      analytics.track("Model Updated", {
        model_id: model?.id,
        model_type: type,
        model_name: model?.name || model?.title,
        source_id: source?.id,
        source_type: source?.type,
      });
    };

    if (updateResourceOrDraft && resourceType === ResourceToPermission.Model) {
      await updateResourceOrDraft(
        {
          _set: update,
          modelColumns,
        },
        () => undefined,
        updateFunc,
        model?.draft || false,
      );
    } else {
      await updateFunc();
    }
  };

  return update;
};

const invalidateColumnMetadata = (model: Model, columnsInput: ModelColumnInput[]) => {
  const { columns } = model;

  for (const column of columns || []) {
    if (!column.metadata?.properties) continue;

    for (const input of columnsInput) {
      if (column.name === input.name && !input.metadata) {
        input.metadata = {
          ...column.metadata,
          properties: null,
        };
      }
    }
  }

  return columnsInput;
};

export const aliasColumns = (columns: any, modelColumns: any) =>
  columns?.map((column) => ({
    ...column,
    name: getColumnName(column.name, modelColumns),
  }));

export const aliasRow = (row, columns) => {
  const keys = Object.keys(row);

  // Add preview disabled columns back to the row
  const previewDisabledColumns = columns?.filter(({ disable_preview }) => disable_preview);
  previewDisabledColumns?.forEach(({ name }) => keys.push(name));

  return keys.reduce((rest, key) => {
    const modelColumn = columns?.find(({ name }) => name === key);
    const alias = modelColumn?.alias;
    const previewDisabled = modelColumn?.disable_preview;
    const redactedText = modelColumn?.redacted_text;
    const value = row[key];

    const newKey = alias || key;
    const newValue = previewDisabled ? redactedText ?? "<REDACTED BY HIGHTOUCH>" : value;

    return { [newKey]: newValue, ...rest };
  }, {});
};

export const getColumnName = (queryColumn: string, modelColumns: ModelColumns[]): string => {
  const alias = modelColumns?.find(({ name }) => name === queryColumn)?.alias;
  return alias || queryColumn;
};

export const isTimestampColumn = (column: ObjectColumnFragment): boolean => {
  return column.type === ColumnType.Timestamp || column.custom_type === ColumnType.Timestamp;
};

export const QueryTypeIcon: FC<Readonly<{ type: QueryType }>> = ({ type }) => {
  switch (type) {
    case QueryType.RawSql:
      return <SQLIcon size={18} color="var(--chakra-colors-text-secondary)" />;
    case QueryType.Visual:
      return (
        <Box as="svg" xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 0 24 24" fill="text.secondary">
          <path d="M15.2 7.8a2.8 2.8 0 1 1-5.6 0 2.8 2.8 0 0 1 5.6 0Zm.933 8.4a3.733 3.733 0 1 0-7.466 0V19h7.466v-2.8Zm3.734-6.533a1.867 1.867 0 1 1-3.734 0 1.867 1.867 0 0 1 3.734 0ZM18 19v-2.8a5.57 5.57 0 0 0-.7-2.712 2.804 2.804 0 0 1 3.5 2.712V19H18ZM4.933 9.667a1.867 1.867 0 1 0 3.734 0 1.867 1.867 0 0 0-3.734 0ZM6.8 19v-2.8c0-.984.254-1.909.7-2.712A2.804 2.804 0 0 0 4 16.2V19h2.8Z" />
        </Box>
      );
    case QueryType.DbtModel:
      return <DBTIcon size={14} color="var(--chakra-colors-text-secondary)" />;
    case QueryType.Table:
      return <TableIcon size={16} color="var(--chakra-colors-text-secondary)" />;
    default:
      return (
        <Box as="svg" xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 0 24 24" fill="text.secondary">
          <path
            fillRule="evenodd"
            d="M4.25 6.75a2.5 2.5 0 0 1 2.5-2.5h10.5a2.5 2.5 0 0 1 2.5 2.5v10.5a2.5 2.5 0 0 1-2.5 2.5H6.75a2.5 2.5 0 0 1-2.5-2.5V6.75Zm2.5-1a1 1 0 0 0-1 1v10.5a1 1 0 0 0 1 1h10.5a1 1 0 0 0 1-1V6.75a1 1 0 0 0-1-1H6.75Zm8.75 2a.75.75 0 0 1 .75.75v7a.75.75 0 0 1-1.5 0v-7a.75.75 0 0 1 .75-.75ZM12 10.375a.75.75 0 0 1 .75.75V15.5a.75.75 0 0 1-1.5 0v-4.375a.75.75 0 0 1 .75-.75ZM8.5 13a.75.75 0 0 1 .75.75v1.75a.75.75 0 0 1-1.5 0v-1.75A.75.75 0 0 1 8.5 13Z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M4 6.75A2.75 2.75 0 0 1 6.75 4h10.5A2.75 2.75 0 0 1 20 6.75v10.5A2.75 2.75 0 0 1 17.25 20H6.75A2.75 2.75 0 0 1 4 17.25V6.75ZM6.75 4.5A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75Zm0 1.5a.75.75 0 0 0-.75.75v10.5c0 .414.336.75.75.75h10.5a.75.75 0 0 0 .75-.75V6.75a.75.75 0 0 0-.75-.75H6.75Zm-1.25.75c0-.69.56-1.25 1.25-1.25h10.5c.69 0 1.25.56 1.25 1.25v10.5c0 .69-.56 1.25-1.25 1.25H6.75c-.69 0-1.25-.56-1.25-1.25V6.75Zm9 1.75a1 1 0 1 1 2 0v7a1 1 0 1 1-2 0v-7Zm1-.5a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 1 0v-7a.5.5 0 0 0-.5-.5ZM11 11.125a1 1 0 1 1 2 0V15.5a1 1 0 1 1-2 0v-4.375Zm1-.5a.5.5 0 0 0-.5.5V15.5a.5.5 0 0 0 1 0v-4.375a.5.5 0 0 0-.5-.5ZM7.5 13.75a1 1 0 1 1 2 0v1.75a1 1 0 1 1-2 0v-1.75Zm1-.5a.5.5 0 0 0-.5.5v1.75a.5.5 0 0 0 1 0v-1.75a.5.5 0 0 0-.5-.5Z"
            clipRule="evenodd"
          />
        </Box>
      );
  }
};
