import { FC, useEffect, useMemo, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Spinner,
  Switch,
  useToast,
  SectionHeading,
  Button,
  Column,
  Row,
  Select,
  Combobox,
  Text,
  TextInput,
  IconButton,
  ButtonGroup,
  SearchInput,
  EditableText,
  Tooltip,
  ConfirmationDialog,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { merge, set } from "lodash";
import {
  useForm,
  useFieldArray,
  Controller,
  Control,
  SubmitHandler,
  UseFormWatch,
  UseFormSetValue,
  FieldErrors,
} from "react-hook-form";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import * as y from "yup";

import relationshipPlaceholder from "src/assets/placeholders/relationship.svg";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import throughRelationshipPlaceholder from "src/assets/placeholders/through-relationship.svg";
import {
  Relationship,
  useCreateRelationshipMutation,
  useRelationshipModelsQuery,
  useRelationshipsQuery,
  useAvailableThroughRelationshipsQuery,
  useUpdateRelationshipMutation,
  useCreateThroughRelationshipMutation,
  useUpdateThroughRelationship2Mutation,
  ThroughInstance,
  useThroughRelationshipsQuery,
  ThroughRelationshipsQuery,
  useDeleteThroughRelationshipMutation,
  useDeleteRelationship2Mutation,
} from "src/graphql";
import { SchemaModelType } from "src/types/schema";
import { FieldError } from "src/ui/field";
import { BidirectionalArrowIcon } from "src/ui/icons/new-icons";
import { MergeIcon } from "src/ui/icons/new-icons/merge";
import { Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/ui/text";
import { getSearchRegExp } from "src/utils/string";

export const getDefaultRelationship = (id: string): Omit<Relationship, "edgeId" | "reverseEdgeId"> => ({
  from: id,
  to: "",
  cardinality: "one-to-many",
  joinMappings: [{ from: "", to: "" }],
  isMergingIntoFrom: false,
  isMergingIntoTo: false,
  fromName: null,
  toName: null,
});

export const Relationships: FC<
  Readonly<{
    type: SchemaModelType;
    sourceId: string;
    modelId: string;
  }>
> = ({ type, modelId, sourceId }) => {
  const [search, setSearch] = useState("");
  const { data: relationships } = useRelationshipsQuery(
    { id: String(modelId) },
    { enabled: Boolean(modelId), select: (data) => data.listRelationships.relationships },
  );
  const { data: throughRelationships } = useThroughRelationshipsQuery(
    { modelId: String(modelId) },
    { enabled: Boolean(modelId), select: (data) => data.model_relationships },
  );
  const { data: models } = useRelationshipModelsQuery({ sourceId }, { select: (data) => data.segments });

  const navigate = useNavigate();

  const relationshipColumns: TableColumn[] = [
    {
      name: "Name",
      cell: (row) => {
        const toModel = models?.find((m) => String(m.id) === String(row.to));
        return (
          <Row gap={2} align="center">
            <Text isTruncated fontWeight="medium">
              {row.toName || toModel?.name}
            </Text>
            {row.isMergingIntoTo && (
              <Tooltip message={`Merging columns into ${toModel?.name}`}>
                <MergeIcon />
              </Tooltip>
            )}
          </Row>
        );
      },
    },
    {
      name: "Model",
      cell: (row) => {
        const toModel = models?.find((m) => String(m.id) === String(row.to));
        return <Text isTruncated>{toModel?.name}</Text>;
      },
    },
    {
      name: "Type",
      cell: (row) => <Text>{cardinalityOptions.find((opt) => opt.value === row.cardinality)?.label}</Text>,
    },
  ];

  const throughRelationshipColumns: TableColumn[] = [
    {
      name: "Name",
      cell: (row) => <TextWithTooltip fontWeight="medium">{row.name ?? row.to_model.name}</TextWithTooltip>,
    },
    {
      name: "Access",
      cell: (row) => <TextWithTooltip>{row.to_model.name}</TextWithTooltip>,
    },
    {
      name: "Through",
      cell: (row) => (
        <TextWithTooltip>
          {row.through_relationships?.map(({ relationship: { name, to_model } }) => `${name ?? to_model.name}`).join(" -> ")}
        </TextWithTooltip>
      ),
    },
  ];

  const filteredRelationships = useMemo(() => {
    const regex = getSearchRegExp(search, "i");
    return (relationships ?? []).filter(({ toName }) => regex.test(String(toName)));
  }, [search, relationships]);

  if (!models || !relationships) {
    return <Spinner size="lg" m="auto" />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          <Column gap={10} flex={1} overflow="auto" p={6}>
            <Column gap={4}>
              <Row align="center" justify="space-between" gap={4}>
                <SectionHeading>Relationships</SectionHeading>
                <Row align="center" gap={4}>
                  <SearchInput
                    width="xs"
                    placeholder="Search relationships..."
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate({ pathname: "new", search: window.location.search });
                    }}
                  >
                    Add relationship
                  </Button>
                </Row>
              </Row>
              <Table
                primaryKey="edgeId"
                columns={relationshipColumns}
                data={filteredRelationships}
                onRowClick={(row) => {
                  navigate({ pathname: String(row.edgeId), search: window.location.search });
                }}
                placeholder={{
                  image: search ? searchPlaceholder : relationshipPlaceholder,
                  title: search ? "No relationships match your search" : "This model contains no relationships",
                  body: search ? null : "Add relationship to increase the query capabilities of your audiences.",
                }}
              />
            </Column>
            {(type === SchemaModelType.Parent || throughRelationships?.length) && (
              <Column gap={4}>
                <Row align="center" justify="space-between" gap={4}>
                  <SectionHeading>Through relationships</SectionHeading>
                  <Row align="center" gap={4}>
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigate({ pathname: "through/new", search: window.location.search });
                      }}
                    >
                      Add through relationship
                    </Button>
                  </Row>
                </Row>
                <Table
                  columns={throughRelationshipColumns}
                  data={throughRelationships}
                  onRowClick={(row) => navigate({ pathname: `through/${row.id}`, search: window.location.search })}
                  placeholder={{
                    image: throughRelationshipPlaceholder,
                    title: "This model contains no through relationships",
                    body: "Add a relationship and then to increase the query capabilities of your audiences.",
                  }}
                />
              </Column>
            )}
          </Column>
        }
      />
      <Route path=":id" element={<DirectRelationshipForm models={models} modelId={modelId} sourceId={sourceId} />} />
      <Route path="new" element={<DirectRelationshipForm models={models} modelId={modelId} sourceId={sourceId} />} />
      <Route
        path="through/:id"
        element={
          <ThroughRelationshipForm throughRelationshipModels={throughRelationships} modelId={modelId} sourceId={sourceId} />
        }
      />
      <Route
        path="through/new"
        element={
          <ThroughRelationshipForm throughRelationshipModels={throughRelationships} modelId={modelId} sourceId={sourceId} />
        }
      />
    </Routes>
  );
};

const DirectRelationshipForm: FC<
  Readonly<{
    models: { id: string; name: string; columns: Column[] }[];
    sourceId: string;
    modelId: string;
  }>
> = ({ models, modelId, sourceId }) => {
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const updateRelationshipMutation = useUpdateRelationshipMutation();
  const createRelationshipMutation = useCreateRelationshipMutation();
  const deleteRelationshipMutation = useDeleteRelationship2Mutation();
  const { data: relationships } = useRelationshipsQuery(
    { id: String(modelId) },
    { enabled: Boolean(modelId), select: (data) => data.listRelationships.relationships },
  );

  const fromModel = models?.find((m) => String(m.id) === String(modelId));
  const relationship = relationships?.find(
    (r) => String(r.edgeId) === String(params?.id) || String(r.reverseEdgeId) === String(params?.id),
  );
  const toModel = models?.find((m) => String(m.id) === String(relationship?.to));

  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<FormValues>({
    resolver: validationResolver(relationshipFormSchema),
    context: models,
    defaultValues: {
      relationship: (relationship
        ? { ...relationship, edgeId: undefined, reverseEdgeId: undefined, toName: relationship.toName ?? toModel?.name }
        : getDefaultRelationship(modelId)) as Relationship,
    },
  });

  const submit: SubmitHandler<FormValues> = async (data) => {
    if (relationship) {
      try {
        await updateRelationshipMutation.mutateAsync({
          edgeId: relationship.edgeId,
          reverseEdgeId: relationship.reverseEdgeId,
          relationship: data.relationship,
        });
        toast({
          id: "update-relationship",
          title: "Relationship updated",
          variant: "success",
        });
      } catch (e) {
        toast({
          id: "update-relationship",
          title: "Relationship failed to update",
          variant: "error",
        });
        captureException(e);
      }
    } else {
      try {
        await createRelationshipMutation.mutateAsync({
          relationship: data.relationship,
        });
        toast({
          id: "create-relationship",
          title: "Relationship created",
          variant: "success",
        });
        navigate({ pathname: "/schema-v2/view/relationships", search: window.location.search });
      } catch (e) {
        toast({
          id: "create-relationship",
          title: "Failed to create relationship",
          variant: "error",
        });
        captureException(e);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRelationshipMutation.mutateAsync({
        edgeId: relationship?.edgeId,
        reverseEdgeId: relationship?.reverseEdgeId,
      });
      toast({
        id: "delete-relationships",
        title: "Relationship deleted",
        variant: "success",
      });
      navigate({ pathname: "/schema-v2/view/relationships", search: window.location.search });
    } catch (e) {
      toast({
        id: "delete-relationships",
        title: "Relationship failed to delete",
        variant: "error",
      });
      captureException(e);
    }
  };

  return (
    <Column height="100%">
      <Column flex={1} p={6} overflow="auto">
        <Row align="center" mb={4} gap={2}>
          <Link to={{ pathname: "/schema-v2/view/relationships", search: window.location.search }}>
            <Box as={Text} color="text.secondary" _hover={{ color: "text.primary" }}>
              Relationships
            </Box>
          </Link>
          <Text fontWeight="medium" color="text.secondary">
            /
          </Text>
          <Text color="text.secondary" fontWeight="medium">
            {relationship ? relationship.toName || toModel?.name : "New relationship"}
          </Text>
        </Row>
        {fromModel ? (
          <RelationshipFields
            sourceId={sourceId}
            fromModel={fromModel}
            control={control}
            watch={watch}
            setValue={setValue}
            errors={errors}
          />
        ) : (
          <Spinner size="lg" m="auto" />
        )}
      </Column>
      <Row bg="white" borderTop="1px" borderColor="base.border" p={4} justify="space-between">
        <ButtonGroup>
          <Button
            size="lg"
            variant="primary"
            isLoading={updateRelationshipMutation.isLoading}
            isDisabled={!isDirty}
            onClick={handleSubmit(submit)}
          >
            Save changes
          </Button>
          <Button
            size="lg"
            isDisabled={!isDirty}
            onClick={() => {
              if (relationship) {
                reset();
              } else {
                navigate({ pathname: "/schema-v2/view/relationships", search: window.location.search });
              }
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
        {relationship && (
          <Button
            size="lg"
            variant="warning"
            onClick={() => {
              setIsDeleting(true);
            }}
          >
            Delete
          </Button>
        )}
      </Row>
      <ConfirmationDialog
        confirmButtonText="Delete"
        isOpen={isDeleting}
        title="Delete relationship"
        variant="danger"
        onClose={() => setIsDeleting(false)}
        onConfirm={handleDelete}
      >
        <Text>Are you sure you want to delete this relationship?</Text>
      </ConfirmationDialog>
    </Column>
  );
};

const ThroughRelationshipForm: FC<
  Readonly<{
    throughRelationshipModels?: ThroughRelationshipsQuery["model_relationships"];
    sourceId: string;
    modelId: string;
  }>
> = ({ modelId, sourceId, throughRelationshipModels }) => {
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const updateRelationshipMutation = useUpdateThroughRelationship2Mutation();
  const createRelationshipMutation = useCreateThroughRelationshipMutation();
  const deleteRelationshipMutation = useDeleteThroughRelationshipMutation();

  const { data: availableThroughRelationships } = useAvailableThroughRelationshipsQuery(
    { modelId, sourceId },
    { select: (data) => data.listAvailableThroughRelationships.throughRelationships },
  );

  const currentRelationship = throughRelationshipModels?.find((r) => String(r.id) === String(params?.id));

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<{ id: string | undefined; name: string | undefined; throughId: string | undefined }>();

  const id = watch("id");
  const throughId = watch("throughId");

  const throughOptions = availableThroughRelationships?.find((r) => String(r.id) === String(id))?.through;

  const submit: SubmitHandler<any> = async (data) => {
    const throughInstance = throughOptions?.find((t) => String(t.id) === String(data.throughId)) as ThroughInstance;

    if (currentRelationship) {
      try {
        await updateRelationshipMutation.mutateAsync({
          id: currentRelationship.id,
          object: {
            to_model_id: data.id,
            name: data.name,
          },
          throughRelationships: throughInstance.pathSegments.map((path) => ({
            relationship_id: currentRelationship.id,
            path_segment_id: path.id,
          })),
        });
        toast({
          id: "update-through-relationships",
          title: "Through relationship updated",
          variant: "success",
        });
      } catch (e) {
        toast({
          id: "update-through-relationships",
          title: "Through relationship failed to update",
          variant: "error",
        });
        captureException(e);
      }
    } else {
      try {
        await createRelationshipMutation.mutateAsync({
          object: {
            name: data.name,
            is_direct_relationship: false,
            from_model_id: modelId,
            to_model_id: data.id,
            through_relationships: {
              data: throughInstance.pathSegments.map((path) => ({ path_segment_id: path.id })),
            },
          },
        });
        toast({
          id: "create-through-relationships",
          title: "Through relationship created",
          variant: "success",
        });
        navigate({ pathname: "/schema-v2/view/relationships", search: window.location.search });
      } catch (e) {
        toast({
          id: "create-through-relationships",
          title: "Through relationship failed to create",
          variant: "error",
        });
        captureException(e);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteRelationshipMutation.mutateAsync({
        id: currentRelationship?.id,
      });
      toast({
        id: "delete-through-relationships",
        title: "Through relationship deleted",
        variant: "success",
      });
      navigate({ pathname: "/schema-v2/view/relationships", search: window.location.search });
    } catch (e) {
      toast({
        id: "delete-through-relationships",
        title: "Through relationship failed to delete",
        variant: "error",
      });
      captureException(e);
    }
  };

  useEffect(() => {
    if (!throughId && throughOptions && throughOptions.length === 1) {
      setValue("throughId", throughOptions[0]?.id, { shouldDirty: true });
    }
  }, [throughId, throughOptions]);

  useEffect(() => {
    if (currentRelationship && availableThroughRelationships?.length) {
      reset({
        id: String(currentRelationship.to_model.id),
        name: currentRelationship.name ?? currentRelationship.to_model.name,
        throughId: availableThroughRelationships
          ?.find((r) => String(r.id) === String(currentRelationship.to_model.id))
          ?.through.find((t) =>
            t.pathSegments.every((path) =>
              currentRelationship?.through_relationships?.some((tr) => String(tr.relationship.id) === String(path.id)),
            ),
          )?.id,
      });
    }
  }, [availableThroughRelationships]);

  return (
    <Column height="100%">
      <Column flex={1} p={6} overflow="auto">
        <Row align="center" mb={4} gap={2}>
          <Link to={{ pathname: "/schema-v2/view/relationships", search: window.location.search }}>
            <Box as={Text} color="text.secondary" _hover={{ color: "text.primary" }}>
              Through relationships
            </Box>
          </Link>
          <Text fontWeight="medium" color="text.secondary">
            /
          </Text>
          <Text color="text.secondary" fontWeight="medium">
            {currentRelationship ? currentRelationship.name : "New through relationship"}
          </Text>
        </Row>
        <Column gap={4}>
          <Controller
            control={control}
            name="name"
            render={({ field }) =>
              field.value ? (
                <EditableText value={field.value} onChange={field.onChange} onSubmit={field.onChange} fontWeight="medium" />
              ) : (
                <Text fontWeight="medium" color="text.secondary">
                  New through relationship
                </Text>
              )
            }
          />
          <Row align="center" gap={2}>
            <Text fontWeight="medium">Access</Text>
            <Controller
              control={control}
              name="id"
              render={({ field }) => (
                <Combobox
                  width="2xs"
                  options={availableThroughRelationships ?? []}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    setValue("throughId", undefined);
                    const option = availableThroughRelationships?.find((r) => String(r.id) === String(value));
                    setValue("name", option?.name);
                  }}
                  optionLabel={(option) => `${option.name}`}
                  optionValue={(option) => option.id}
                  placeholder="Select a model..."
                />
              )}
            />
            {id && (
              <>
                <Text fontWeight="medium">through</Text>
                <Controller
                  control={control}
                  name="throughId"
                  render={({ field }) => (
                    <Combobox
                      width="2xs"
                      options={throughOptions ?? []}
                      value={field.value}
                      onChange={field.onChange}
                      optionValue={(option) => option.id}
                      optionLabel={(option) => option.pathSegments.map((p) => p.name).join(" -> ")}
                      placeholder="Select a relationship..."
                    />
                  )}
                />
              </>
            )}
          </Row>
        </Column>
      </Column>
      <Row bg="white" borderTop="1px" borderColor="base.border" p={4} justify="space-between">
        <ButtonGroup>
          <Button size="lg" variant="primary" isDisabled={!isDirty} onClick={handleSubmit(submit)}>
            Save changes
          </Button>
          <Button
            size="lg"
            isDisabled={!isDirty}
            onClick={() => {
              if (currentRelationship) {
                reset();
              } else {
                navigate({ pathname: "/schema-v2/view/relationships", search: window.location.search });
              }
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
        {currentRelationship && (
          <Button
            size="lg"
            variant="warning"
            onClick={() => {
              setIsDeleting(true);
            }}
          >
            Delete
          </Button>
        )}
      </Row>
      <ConfirmationDialog
        confirmButtonText="Delete"
        isOpen={isDeleting}
        title="Delete through relationship"
        variant="danger"
        onClose={() => setIsDeleting(false)}
        onConfirm={handleDelete}
      >
        <Text>Are you sure you want to delete this through relationship?</Text>
      </ConfirmationDialog>
    </Column>
  );
};

type Column = {
  name: string;
  alias: string | null;
  type: string;
  custom_type: string | null;
};

export const RelationshipFields: FC<
  Readonly<{
    errors: FieldErrors<FormValues>;
    sourceId: string;
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    watch: UseFormWatch<FormValues>;
    fromModel: {
      id: string;
      name: string;
      columns: Column[];
    };
    // toModel is used for creation flows where there is only a single relationship being created
    toModel?: { name: string; columns: Column[] };
  }>
> = ({ fromModel, sourceId, control, watch, setValue, errors, ...props }) => {
  const { data: relationships } = useRelationshipsQuery(
    { id: String(fromModel.id) },
    { enabled: Boolean(fromModel), select: (data) => data.listRelationships.relationships },
  );
  const { data: models } = useRelationshipModelsQuery({ sourceId }, { select: (data) => data.segments });

  const hasMergableRelationships = relationships?.some(
    (r) => (r.cardinality === "one-to-one" || r.cardinality === "many-to-one") && r.to,
  );

  const to = watch("relationship.to");

  const toModels = models?.filter((m) => String(m.id) !== String(fromModel?.id));
  const toModel = props.toModel ?? models?.find((m) => String(m.id) === String(to));

  return (
    <Column gap={4} flex={1} pb={8}>
      <Row justify="space-between" align="center" width="100%">
        <Controller
          control={control}
          name="relationship.toName"
          render={({ field }) =>
            field.value ? (
              <EditableText value={field.value} onChange={field.onChange} onSubmit={field.onChange} fontWeight="medium" />
            ) : (
              <Text fontWeight="medium" color="text.secondary">
                New relationship
              </Text>
            )
          }
        />
      </Row>
      <Row gap={2}>
        <Row flex={1}>
          <TextInput isReadOnly value={fromModel.name} width="100%" />
        </Row>
        <Controller
          control={control}
          name="relationship.cardinality"
          render={({ field, fieldState: { error } }) => (
            <Select
              width="100%"
              isInvalid={Boolean(error)}
              options={cardinalityOptions}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="relationship.to"
          render={({ field, fieldState: { error } }) => (
            <Column flex={1}>
              {props.toModel ? (
                <TextInput width="100%" isReadOnly value={toModel?.name ?? ""} placeholder="Name your model..." />
              ) : (
                <Combobox
                  width="100%"
                  isInvalid={Boolean(error)}
                  placeholder="Select a model..."
                  options={toModels ?? []}
                  value={field.value}
                  onChange={field.onChange}
                  optionLabel={(option) => option.name}
                  optionValue={(option) => String(option.id)}
                />
              )}
              <FieldError error={error?.message} />
            </Column>
          )}
        />
      </Row>
      <Mapper control={control} watch={watch} errors={errors} toModel={toModel} fromModel={fromModel} />
      {hasMergableRelationships && (
        <Column gap={4}>
          <SectionHeading>Merge columns</SectionHeading>
          <Column gap={4}>
            {relationships?.map((relationship, index) => {
              const toModel = models?.find((m) => String(m.id) === String(relationship.to));
              if ((relationship.cardinality === "many-to-one" || relationship.cardinality === "one-to-one") && toModel) {
                return (
                  <Row gap={2} align="center" key={index}>
                    <Switch
                      isChecked={relationship.isMergingIntoFrom}
                      onChange={(value) => setValue(`relationship.isMergingIntoFrom`, value)}
                    />
                    <Text fontWeight="medium">
                      Merge {toModel?.name} columns into {fromModel.name}
                    </Text>
                  </Row>
                );
              }
              return null;
            })}
          </Column>
        </Column>
      )}
    </Column>
  );
};

const Mapper: FC<
  Readonly<{
    errors: FieldErrors<FormValues>;
    control: Control<FormValues>;
    watch: UseFormWatch<FormValues>;
    toModel: { name: string; columns: Column[] } | undefined;
    fromModel: { name: string; columns: Column[] };
  }>
> = ({ control, watch, errors, toModel, fromModel }) => {
  const [multipleJoinKeys, setMultipleJoinKeys] = useState(false);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "relationship.joinMappings",
  });

  const currentJoinMappings = watch("relationship.joinMappings");

  return (
    <Column gap={4}>
      {fields.map((field, index) => {
        const mappingError = errors?.relationship?.joinMappings?.[index];
        const isRemovable = multipleJoinKeys && fields.length > 2 && index !== fields.length - 1;
        const otherJoinMappings = currentJoinMappings.filter((_, i) => i !== index);
        const fromOptions = fromModel.columns.filter((c) => !otherJoinMappings.some((j) => j.from === c.name));
        const toOptions = toModel?.columns.filter((c) => !otherJoinMappings.some((j) => j.to === c.name)) ?? [];
        return (
          <Column key={field.id}>
            <Row align="flex-start" gap={2} flex={1}>
              <Controller
                control={control}
                name={`relationship.joinMappings.${index}.from`}
                render={({ field, fieldState: { error } }) => (
                  <Column flex={1}>
                    <Combobox
                      width="100%"
                      isInvalid={Boolean(error || mappingError)}
                      placeholder={`Select a column from ${fromModel.name}...`}
                      value={field.value}
                      onChange={(value) => {
                        if (multipleJoinKeys && index === fields.length - 1) {
                          append({ from: "", to: "" });
                        }
                        field.onChange(value);
                      }}
                      options={fromOptions}
                      optionLabel={(column) => column.alias || column.name}
                      optionValue={(column) => column.name}
                    />
                    <FieldError error={error?.message} />
                  </Column>
                )}
              />
              <Row height="32px" align="center" color="text.secondary">
                <BidirectionalArrowIcon />
              </Row>
              <Controller
                control={control}
                name={`relationship.joinMappings.${index}.to`}
                render={({ field, fieldState: { error } }) => (
                  <Column flex={1}>
                    <Combobox
                      width="100%"
                      isInvalid={Boolean(error || mappingError)}
                      placeholder={toModel?.name ? `Select a column from ${toModel.name}...` : "Select a column..."}
                      isDisabled={!toModel?.name}
                      value={field.value}
                      onChange={(value) => {
                        if (multipleJoinKeys && index === fields.length - 1) {
                          append({ from: "", to: "" });
                        }
                        field.onChange(value);
                      }}
                      options={toOptions}
                      optionLabel={(column) => column.alias || column.name}
                      optionValue={(column) => column.name}
                    />
                    <FieldError error={error?.message} />
                  </Column>
                )}
              />
              <Row
                pointerEvents={isRemovable ? "auto" : "none"}
                visibility={isRemovable ? "visible" : "hidden"}
                display={multipleJoinKeys && currentJoinMappings.length > 2 ? "flex" : "none"}
              >
                <IconButton aria-label="Remove join key" icon={XMarkIcon} onClick={() => remove(index)} />
              </Row>
            </Row>
            <FieldError error={mappingError?.message} />
          </Column>
        );
      })}

      <Row align="center" gap={2}>
        <Switch
          isChecked={multipleJoinKeys}
          onChange={(value) => {
            if (value) {
              setMultipleJoinKeys(true);
              append({ from: "", to: "" });
            } else {
              setMultipleJoinKeys(false);
              const firstMapping = currentJoinMappings[0];
              if (firstMapping) {
                replace(firstMapping);
              }
            }
          }}
        />
        <Text fontWeight="medium">Multiple join keys</Text>
      </Row>
    </Column>
  );
};

export const relationshipFormSchema = y.object().shape({
  relationship: y.object().shape({
    from: y.string().required("Select a model"),
    to: y.string().required("Select a model"),
    cardinality: y.string().required("Select a cardinality"),
    joinMappings: y
      .array()
      .of(
        y.object().shape({
          to: y.string().required("Select a column"),
          from: y.string().required("Select a column"),
        }),
      )
      .required(),
    isMergingIntoFrom: y.boolean(),
    isMergingIntoTo: y.boolean(),
  }),
});

type FormValues = {
  relationship: Relationship;
};

export const cardinalityOptions = [
  {
    value: "one-to-one",
    label: "1:1",
  },
  { value: "one-to-many", label: "1:many" },
  { value: "many-to-one", label: "many:1" },
];

export const validationResolver = (schema: y.ObjectSchema) => async (data, context, options) => {
  const filteredData = data.relationship
    ? {
        ...data,
        relationship: { ...data.relationship, joinMappings: data.relationship.joinMappings.filter((j) => j.from && j.to) },
      }
    : data;
  const joinMappingErrors = {};
  if (data.relationship) {
    const toModel = context.toModel ?? context.models?.find((m) => String(m.id) === String(data.relationship.to));
    const fromModel = context.models?.find((m) => String(m.id) === String(data.relationship.from));
    for (const [nestedIndex, mapping] of filteredData.relationship.joinMappings.entries()) {
      const fromColumn = fromModel?.columns.find((c) => c.name === mapping.from);
      const toColumn = toModel?.columns.find((c) => c.name === mapping.to);
      if (fromColumn && toColumn) {
        const fromType = fromColumn?.custom_type ?? fromColumn?.type;
        const toType = toColumn?.custom_type ?? toColumn?.type;
        if (fromType !== toType) {
          set(joinMappingErrors, `relationship.joinMappings.${nestedIndex}`, {
            message: `Types must match between columns. Column ${fromColumn.name} is a ${fromType} whereas ${toColumn.name} is a ${toType}.`,
          });
        }
      }
    }
  }
  const { values, errors } = await yupResolver(schema)(filteredData, context, options);
  return {
    values: Object.keys(joinMappingErrors).length > 0 ? {} : values,
    errors: merge(errors, joinMappingErrors),
  };
};
